import { SmallCardsCarouselStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { storyblokEditable } from '@storyblok/js';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import clsx from 'clsx';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';

export const StoryblokSmallCardsCarousel: StoryblokReactComponent<SmallCardsCarouselStoryblok> = ({ component, story }) => {
  const cta = component.cta?.at(0);

  return (
    <section className="flex flex-col items-center pt-20 lg:pb-24 " {...storyblokEditable(component)}>
      {component.title && (
        <h2 className="px-5 lg:px-20 text-neutral-darker text-center font-[550] text-5xl max-w-4xl">{component.title}</h2>
      )}
      {component.subtitle && <p className="px-5 lg:px-20 text-neutral-lighter font-normal text-xl mt-6">{component.subtitle}</p>}
      {component.cards && component.cards.length > 0 && (
        <div className="flex flex-row overflow-x-scroll overflow-y-visible lg:overflow-auto w-full lg:w-fit shrink-0 lg:shrink py-14 lg:shadow-2 lg:mx-20 lg:px-14 lg:gap-6 lg:rounded-3xl">
          {component.cards.map((card, i) => (
            <div
              className={clsx(
                i === 0 && 'ml-5 lg:ml-0',
                'min-w-[280px] lg:min-w-[210px] lg:w-[210px] mr-5 lg:mr-0 px-10 lg:px-5 py-10 lg:py-5 rounded-xl shadow-2 lg:shadow-none flex flex-col items-center',
              )}
              key={card._uid}
              {...storyblokEditable(card)}
            >
              {card.image && card.image.filename ? (
                <Image
                  className="size-36 rounded-full object-cover"
                  src={card.image.filename}
                  alt={card.image.alt ?? ''}
                  {...getImageDimensions(card.image)}
                />
              ) : (
                <div className="size-36 rounded-full bg-background-dark" />
              )}
              <h3 className="mt-5 flex flex-col text-neutral-darker text-xl text-center">
                {card.title && <span className="whitespace-nowrap">{card.title}</span>}
                {card.subtitle && <span className="whitespace-nowrap">{card.subtitle}</span>}
              </h3>
              {card.description && <p className="mt-3 text-center text-neutral-lighter">{card.description}</p>}
            </div>
          ))}
        </div>
      )}

      {cta && (
        <Link
          className="transition-colors bg-primary text-white py-5 px-14 rounded hover:bg-primary-hover focus:bg-primary-hover lg:mt-16"
          href={resolveWebLink(cta.link, { component: cta, story })}
        >
          {cta.label}
        </Link>
      )}
    </section>
  );
};
