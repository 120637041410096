'use client';

import { StoryblokReactComponent } from '@/storyblok/components/componentsMap';
import { FooterButtonStoryblok } from '@/types/types-storyblok';

export const FooterButton: StoryblokReactComponent<FooterButtonStoryblok> = ({ component: link }) => {
  return (
    <button
      onClick={() => {
        if (window.CookieConsent) {
          window.CookieConsent?.show();
        } else {
          window.alert('I Cookie non sono abilitati in questo browser');
        }
      }}
      className="text-md text-primary"
    >
      {link.title}
    </button>
  );
};
